import React from "react";
import Layout from '../layouts/index';
import image from "../images/404.png";

const bgContainer = {
  backgroundImage: `url(${image})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '60vh',
}

const container = {
  backgroundColor: '#40A4F4',
  marginTop: '80px'
}

const text = {
  textAlign: 'center',
  color: 'white',
  fontWeight: 'bold',
  fontSize: '2rem'
}

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
       <div style={container}>
        <div className="row">
          <div style={bgContainer} className="col-12">
          </div>
          <div className='col-12 pb-6'>
            <h2 style={text}>
              Oops! Page Not Found.
            </h2>
          </div>
        </div> 
      </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
